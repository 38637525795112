import React from "react"
import Container from "../components/container"

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <div className="content has-text-centered">
            <p>
              &copy; 2010-2021 <strong>睡吧</strong> 版权所有
            </p>
          </div>
        </Container>
      </footer>
    );
  }
}
