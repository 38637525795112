import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import Navigation from "./navigation"
import Footer from './footer'
import Sidebar from "./sidebar"

const getItemsFromEdges = (articles, type) => {
  return articles.map(article => ({
    title: article.title,
    link: `/${type}/${article.id}`
  }));
}

const getSidebar = (edges, type) => {
  return edges.map(edge => {
    const items = getItemsFromEdges(edge.node.articles, type);

    return {
      title: edge.node.name, id: edge.node.id, items
    }
  });
};

const MainBody = ({ children, hasSidebar, sidebarData }) => {
  if (!hasSidebar) {
    return (
      <section className="section">{children}</section>
    )
  }

  return (
    <section className="section">
      <div className="columns">
        <div className="column is-4 is-4-widescreen">
          <Sidebar menu={sidebarData} />
        </div>
        <div className="column is-8 is-8-widescreen">
          {children}
        </div>
      </div>
    </section>
  )
};

const LayoutQuery = graphql`
  query {
    allStrapiTag(sort: {order: ASC, fields: weight}) {
      edges {
        node {
          name
          id: strapiId
          articles {
            id
            title
          }
        }
      }
    }
    allStrapiCategory(sort: {order: ASC, fields: weight}) {
      edges {
        node {
          name
          id: strapiId
          articles {
            id
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        baiduCode
        keywords
      }
    }
  }
`

export default ({ children, location }) => {

  const renderer = (data) => {
    const { title, keywords } = data.site.siteMetadata;
    const isDoc = location.pathname.slice(0, 10) === `/tutorial/`
    const isShare = location.pathname.slice(0, 7) === `/share/`

    const hasSidebar = isDoc
    const sidebarData =
      hasSidebar ?
        (isShare ? getSidebar(data.allStrapiTag.edges, 'share') : getSidebar(data.allStrapiCategory.edges, 'tutorial')) : {}

    // sticky footer solution comes from:
    // https://github.com/jgthms/bulma/issues/47#issuecomment-603547213
    return (
      <div style={{display: 'flex', minHeight: '100vh', flexDirection: 'column'}}>
        <Helmet defaultTitle={`${title}`} titleTemplate={`%s | ${title}`}>
          <meta name="twitter:site" content="hellosleep" />
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="睡吧 和失眠说再见" />
          <meta name="keywords" content={`${keywords}`} />
          <link
            rel="canonical"
            href={`http://hellosleep.net${location.pathname}`}
          />
          <html lang="en" />
        </Helmet>
        <div style={{flex: 1}}>
          <Navigation />
          <div className="container">
            <MainBody hasSidebar={hasSidebar} sidebarData={sidebarData}>
              {children}
            </MainBody>
          </div>
        </div>

        <Footer />
      </div>
    )
  }

  return (
    <StaticQuery
      query={LayoutQuery}
      render={data => renderer(data)}
    />
  )
}

