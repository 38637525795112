import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/logo-text.png'

export default class Logo extends React.Component {
  render() {
    return (
      <Link className="navbar-item" to="/">
        <img src={logo} alt="睡吧" width="120" height="35" css={{ marginBottom: '0.3rem' }} />
      </Link>
    )
  }
}
