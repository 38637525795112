import React from "react"
import { Link } from 'gatsby'
import Logo from './logo'
import { FaHeart } from "react-icons/fa"

export default class Navigation extends React.Component {
  constructor() {
    super()

    this.navMenu = React.createRef()
    this.navBurger = React.createRef()
    this.onBurgerClick = this.onBurgerClick.bind(this)
  }

  onBurgerClick() {
    this.navBurger.current.classList.toggle('is-active')
    this.navMenu.current.classList.toggle('is-active')
  }

  render() {
    return (
      <nav className="navbar" css={{ boxShadow: '0 1px 0 0 rgba(142,81,199,0.05)' }}>
        <div className="container">
          <div className="navbar-brand">
            <Logo />
            <div
              role="button"
              className="navbar-burger burger"
              data-target={"navigation"}
              tabIndex={0}
              ref={this.navBurger}
              onClick={this.onBurgerClick}
              onKeyDown={this.onBurgerClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div id="navigation" className="navbar-menu" ref={this.navMenu}>
            <div className="navbar-start">
              <Link className="navbar-item" to="/tutorial/">知识库</Link>
              <Link className="navbar-item" to="/share/">经验分享</Link>
              <Link className="navbar-item" to="/blog/">博客</Link>
              <Link className="navbar-item" to="/evaluate/login">睡眠评估</Link>
            </div>

            <div className="navbar-end">
              <p className="navbar-item control">
                <Link
                  className="button is-success"
                  to="/donate/"
                  title="帮助睡吧"
                >
                  <FaHeart style={{ verticalAlign: `middle`, marginRight: 5 }} />
                  {`帮助睡吧`}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
