import React from "react"
import { Link } from 'gatsby'
import PropTypes from "prop-types"

class Sidebar extends React.Component {
  render() {
    const {menu} = this.props;

    return (
      <aside className="menu">
        {menu.map((section, index) => (
           <div key={index}>
             <Link to={`/category/${section.id}`} className="menu-label">{section.title}</Link>
             <ul className="menu-list is-hidden-mobile">
               {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <Link to={item.link}>{item.title}</Link>
                  </li>
                ))}
             </ul>
           </div>
         ))}
      </aside>
    )
  }
}

Sidebar.propTypes = {
  text: PropTypes.object
}

export default Sidebar
